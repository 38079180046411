:local {
  .wrapper {
    z-index: 100;
    position: relative;
  }

  .container {
    left: 0;
    width: 100%;
    bottom: 0px;
    position: fixed;
  }
}
