:local {
    .btnCheckIcon {
        max-height: 15px;
    }

    .btnDotIcon {
        width: 12px;
        height: 12px;
        border-radius: 50%;
    }

    .btnArrow {
        max-height: 10px;

        .container.isExpanded & {
            transform: scaleY(-1);
        }
    }

    .bell {
        max-height: 40px;
    }

    /* For Reveo, their iFrames were set at 720px,
    so our desktop styles start at a min width of 719px */
    @media (min-width: 719px) {
        .btnWidth {
            width: 200px !important;
        }
    }
}
