@import "../../../styles/variables";

:local {
    .container{
        padding: 15px 0;
        display: flex;
        align-items: center;
    }

    .image{
        max-height: 50px;
        max-width: 85.5px;
        height: 100%;
        object-fit: contain;
        margin-right: 10px;
    }

    .name{
        color: $input-color;
    }

    .icon {
        margin-right: 20px;
    }

    .info {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }
}

