:local {
  .tileWrap {
    max-width: 380px;
    padding-top: 50px;
  }
  .imageWrap {
    width: 200px;
    position: absolute;
    transform: translateY(-50%);
  }
  .contentWrap {
    padding-top: 50px;
  }
  .tile:hover {
    cursor: pointer;
  }
}
