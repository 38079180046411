:local {
  @media (min-width: 719px) {
    .desktopContainer {
      text-align: left !important;
    }

    .desktopPoweredByOctane {
      margin-left: 10px;
      margin-top: 0;
      display: inline;
    }

    .desktopCampingWorld {
      margin-top: 0;
      display: inline;
    }

    .desktopCampingWorldMiniProp {
      margin-left: 50px;
      margin-top: 0;
      display: inline;
    }
  }
}
