:local {
    .offersLayoutWarningNote {
        background: #F7F7F7;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 5px;

        svg {
            min-width: 30px;
        }
    }
    .offersLayoutWarningNoteText {
        padding-left: 10px;

        // Desktop styles
        @media (min-width: 768px) {
            padding: 0px 3px;
        }
    }
}
