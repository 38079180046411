:local {
    .spinnerContainer {
        text-align: center;
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
    }
}

/*
Overriding @octane/spark styles to match our existing
in the interim as we move to using more @octane/spark components.

Note: uses global as server-side rendering is broken for some of
the styles used in here (namely that for dropdown options)
and produces inconsistent behavior.
*/
:global(.dropdown_list-dropdown) {

    // Dropdown label
    [data-oid="select-label"] {
        color: #6c757d !important;
        font-size: 1rem !important;
        font-weight: 400 !important;
        line-height: 1.5 !important;
        margin-bottom: 0 !important;
    }

    // Dropdown placeholder
    [data-oid="select-placeholder"] {
        /* Our font style causes taller placeholder to be cut
        off by the end of the placeholder container, add a little
        padding to show all of it */
        padding-right: 0.5rem;
    }

    // Dropdown button
    [data-oid="select-button"] {
        border: 1px solid #ced4da;
        padding-left: 1rem;
        height: 50px;

        &:not([disabled]) {
            cursor: pointer;
        }

        // Matches bootstrap focus styles
        &:focus {
            border-color: #80bdff;
            outline: 0;
            box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
        }
    }

    // Dropdown Options
    [data-oid="select-options"] {
        z-index: 1; // Prevents the menus from going above other elements like our sticky header and footer

        // Dropdown Options container
        div {
            // Responsive expanded dropdown menu height based on if we have room
            max-height: 264px; // Displays 6 items

            /* This is to ensure that on iFramed multi-column experiences
            like Reveo and Camping World don't have dropdowns that go beyond the iFrame */
            @media (min-width: 719px) and (max-height: 840px) {
                max-height: 132px; // When window is small - displays 3 items
            }
        }
    }

    &.error {
        [data-oid="select-button"] {
            // Initial will set it to use the default error red
            border-color: initial;

            /* Need to target the children, the border color
            pulls from the color property */
            * {
                color: var(--jetline-black);
            }
        }
    }
}
