:local {
  .wrapper {
    font-size: 14px;

    &.noVehicleImage {
      padding-left: 15px;
      padding-right: 15px;
    }
  }

  .yourVehicleLabel {
    letter-spacing: 1px;
  }
}
