@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

/*
Colors
 */
@import "./octane-colors";
// map-merge only takes 2 arguments so we need to nest in order to recursively merge
$colors: map-merge(
  $brand-colors,
  map-merge(
    $text-colors,
    map-merge(
      $accent-colors,
      $monochrome-colors,
    )
  )
);

$theme-colors: $contextual-colors;

// Spacing
$sizes: map-merge(
  $sizes,
  (
    "100px": 100px,
    "125px": 125px,
    "200px": 200px,
    "300px": 300px,
    "315px": 315px,
    "350px": 350px,
    "450px": 450px,
    "500px": 500px,
    "550px": 550px,
    "600px": 600px,
    "705px": 705px,
    "800px": 800px,
    "900px": 900px,
  )
);

// Typography
$font-family-sans-serif: "Karla", $font-family-sans-serif;
$font-family-base: $font-family-sans-serif;

// Links
$link-color: $gray-600;
$link-decoration: underline;
$link-hover-color: darken($link-color, 15%);

// Paragraphs
$paragraph-margin-bottom: 0rem;

// Grid
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1024px
);

@include _assert-ascending($container-max-widths, "$container-max-widths");

// Borders
$border-width: 1px;
$border-radius: .25rem;
$border-color: $concrete;

// Forms
$label-color: $text-muted;
$label-margin-bottom: 0rem;

$spark-input-height: 50px; // The height for all of our form fields
$spark-btn-border-radius: 2px;
$input-btn-font-size: 16px; // must be 16px to prevent zoom focus for input fields

$input-color: #01040B;
$input-padding-x: 1rem;
$input-padding-y: calc((#{$spark-input-height} - #{$input-height-border}) / 2);
$input-font-size: $input-btn-font-size;
$input-font-weight: bold;
$input-border-radius: $spark-btn-border-radius;

$input-placeholder-color: #898F99;
$input-placeholder-font-weight: 100;
$input-placeholder-font-style: italic;
$input-placeholder-font-family: "Lato", $input-font-family;

$input-height-inner: 2.25rem; // fixes bizarre bug where input icons messed up only in production build
$input-height-inner-half: 1.125rem; // fixes bizarre bug where input icons messed up only in production build
$input-height-inner-quarter: .5625rem; // fixes bizarre bug where input icons messed up only in production build

// Buttons
$btn-padding-y: .5rem;
$btn-font-size: .875rem;
$btn-border-radius: $spark-btn-border-radius;
$btn-padding-y-lg: 1rem;
$btn-font-size-lg: $input-btn-font-size;
$btn-border-radius-lg: $spark-btn-border-radius;
$btn-line-height-lg: 1;
$btn-border-radius-sm: $spark-btn-border-radius;

// Navs
$navbar-dark-color: $white;
$navbar-dark-brand-color: $white;

// Dropdowns
$dropdown-min-width: 100%;
$dropdown-border-radius: 0rem;
$dropdown-item-padding-y: map_get($spacers, 3);
$dropdown-item-padding-x: map_get($spacers, 3);

// Pagination
$pagination-color: $link-color;
$pagination-hover-color: $link-hover-color;

// Breadcrumbs
$breadcrumb-padding-y: .75rem;
$breadcrumb-padding-x: 0rem;
$breadcrumb-item-padding: .5rem;

$breadcrumb-margin-bottom: 0rem;

$breadcrumb-bg: transparent;
$breadcrumb-divider-color: $gray-600;
$breadcrumb-active-color: $gray-800;
$breadcrumb-divider: quote("/");

$breadcrumb-border-radius: 0;
