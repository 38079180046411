@import "../../../styles/variables";

$pi: 3.14159;
$spinnerSize: 66;
$spinnerRadius: 28;
$spinnerCircumference: 2 * $pi * $spinnerRadius;
$spinnerColors: $gray-400, $gray-600, $primary, $success;

:export {
  spinnerSize: $spinnerSize;
  spinnerRadius: $spinnerRadius;
}

:local {
  .fancySpinner {
    width: 66px;
    height: 66px;
    animation: fancySpinnerAnimation 2s linear infinite;
  }

  .stroke {
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;

    transform: rotate(-90deg);

    @for $i from 1 through 4 {
      &:nth-child(#{$i}) circle {
        stroke: nth($spinnerColors, $i);
        animation: strokeAnimation 3s calc(.2s * (#{$i})) ease infinite;
      }
    }

    circle {
      fill: none;
      stroke-width: 8px;
      stroke-dashoffset: 0;
      stroke-linecap: round;
      stroke-dasharray: 1, 300;
      transform-origin: center center;
    }
  }

  @keyframes :local(fancySpinnerAnimation) {
    100% {
      transform: rotate(360deg)
    }
  }

  @keyframes :local(strokeAnimation) {
    0% {
      stroke-dasharray: 1, 300;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 120, 300;
      stroke-dashoffset: -$spinnerCircumference / 3;
    }
    100% {
      stroke-dasharray: 120, 300;
      stroke-dashoffset: -$spinnerCircumference;
    }
  }
}