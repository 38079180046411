:local {
  .input {
    opacity: 0;
    z-index: -1;
    width: 0.1px;
    height: 0.1px;
    overflow: hidden;
    position: absolute;
    & + label * {
      cursor: pointer;
      pointer-events: none;
    }
  }
}
