:local {
    .reveoButton {
        width: 100%;
        font-size: 16px;

        @media (min-width: 719px) {
            width: fit-content;
            padding: 10px 15px;
        }
    }

    .reveoOrange {
        $reveo-orange: #fb5002;
        border-color: $reveo-orange !important;
        background-color: $reveo-orange !important;

        &:not(:disabled):not(.disabled) {
            &:hover {
                border-color: darken($reveo-orange, 5) !important;
                background-color: darken($reveo-orange, 5) !important;
            }
        }
    }

    .buttonContainer {
        height: 100px;
        display: flex;
        align-items: center;

        @media (min-width: 719px) {
            justify-content: flex-end;
        }
    }
}
