:local {
   @media (min-width: 719px) {
      .container {
         max-width: 600px;
      }
      .form {
         display: grid;
         grid-template-columns: repeat(2, 1fr);
         column-gap: 30px;

         label,
         .field-label {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
         }
      }
      .disclosure_wrapper {
         grid-column: 1 / -1;
         margin-top: 20px;
         width: 100%;
      }
      .reveo_cta_wrapper {
         grid-column: 1 / 3;
         display: flex;
         justify-content: center;
         margin-top: 20px;
         width: 100%;
     }
   }
   @media (min-width: 768px) and (max-width: 800px) {
      .container {
         max-width: 668px;
      }
   }
}
